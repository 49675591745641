@import "../load.scss";

// Image viewer core
.image-viewer {
  width: 100%;
  display: block;
  background-color: black;
  z-index: 3;
  @include position-fixed-all();
  .image-viewer-inner {
    button.close {
      @include remove-webkit();
      padding: 0;
      width: 2rem;
      min-height: 2rem;
      @include position(absolute, $top: 1rem, $right: 1rem);
      background-color: transparent;
      cursor: pointer;
      span {
        @include size(3px);
        background-color: white;
        display: block;
        @include default-transition();
        border-radius: 3px;
        &:first-child {
          transform: rotate(45deg);
        }
        &:last-child {
          margin-top: -3px;
          transform: rotate(-45deg);
        }
      }
      &:hover > span {
        background-color: $base-red;
      }
      @include mobile {
        z-index: 5555 !important;
      }
    }
    button.nav {
      @include remove-webkit();
      @include position(absolute);
      @include background-image-properties(2rem, center center);
      @include background-image("../../images/icons/arrow-right-white.svg");
      @include default-transition();
      background-color: transparent;
      top: calc(50% - 2rem);
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      @include mobile {
        display: none !important;
      }
      &:hover {
        opacity: 0.5;
      }
      &.prev {
        left: 1rem;
        transform: rotate(180deg);
      }
      &.next {
        right: 1rem;
      }
    }
  }
  .spinner-holder {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.image-viewer-content {
  width: 960px;
  height: 100vh;
  margin: 0 auto;
  display: table;
  position: relative;
  .image-viewer-container {
    display: table-cell;
    vertical-align: middle;
    img {
      max-width: 100%;
      max-height: calc(100vh - 12rem);
      display: block;
      margin: 0 auto;
      opacity: 1;
      @include default-transition(opacity, 0.2s);
      &.hide-image {
        opacity: 0;
      }
    }
  }
  @include mobile {
    width: 90%;
  }
}
