@import "../load.scss";

html {
  font-size: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  background-color: black;
  height: 100%;
}

* {
  font-family: "Barlow", sans-serif;
}

#root {
  max-width: 2560px;
  margin: 0 auto;
  height: 100%;
  color: white;
}

#container {
  width: 1024px;
  margin: 0 auto;
}
