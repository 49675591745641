@import "../load.scss";

.video-placeholder {
  position: relative;
  aspect-ratio: 16/9;
  background-color: #333;
  background-size: cover;
  background-position: center center;
  display: block;
}

.album-placeholder {
  position: relative;
  aspect-ratio: 9/14;
  background-color: #333;
  background-size: cover;
  background-position: center center;
  display: block;
}
