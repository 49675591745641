@import "../../styles/load.scss";

.photojournalism {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 2rem;

  @include mobile {
    margin-top: -1rem;
    grid-template-columns: auto;
    grid-gap: 1rem;
  }
}
