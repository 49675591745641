@import "../../styles/load.scss";

.booking-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
  background-color: darken($base-alternative-black, 5%);
  @include mobile {
    padding: 2rem 0;
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .booking-banner-inner {
    text-align: center;
    max-width: 500px;
    @include mobile {
      padding: 0 2rem;
    }
    .diamond-ring {
      width: 64px;
      height: 64px;
    }
    a {
      color: white;
      text-decoration: none;
      padding: 0.75rem 1.5rem;

      border: 1px solid lighten($base-alternative-black, 10%);
      margin-top: 2rem;
      display: inline-flex;
      align-items: center;
      align-self: center;
      gap: 0.5rem;
    }

    @include mobile {
      max-width: 350px;
      margin-bottom: 3rem;
      h2 {
        font-size: 1.15rem;
      }
      p {
        font-size: 0.9rem;
      }
    }
  }
}
