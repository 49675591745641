@import "../load.scss";

header {
  width: 90%;
  max-width: 1440px;
  margin: 0 auto;
  display: block;
  @include mobile {
    padding: 1rem;
  }
  .header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    .header-top {
      img {
        max-width: 8rem;
        @include default-transition();
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
    }
    .header-body {
      display: flex;
      gap: 1rem;
      a {
        @include default-transition();
        display: block;
        color: white;
        text-decoration: none;
        font-size: 1.15rem;
        font-weight: 500;
        position: relative;
        &.active {
          color: $text-gray;
        }
      }
    }
  }
  &.desktop-menu {
    @include mobile {
      display: none !important;
    }
  }
}

.mobile-menu {
  display: none;
  padding: 1rem;
  @include default-transition();
  .col {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
    img {
      max-height: 3rem;
    }
    &:last-child {
      text-align: right;
      button {
        width: 3rem;
        height: 2rem;
        background-color: transparent;
        @include remove-webkit();
        background-size: 2rem;
        background-position: center center;
        background-repeat: no-repeat;
        &.closed {
          background-image: url("../../images/icons/menu-three-lines.svg");
        }
        &.opened {
          background-size: 1.5rem;
          background-image: url("../../images/icons/close-white.svg");
        }
      }
    }
  }
  @include mobile {
    display: block;
  }
}

.side-menu {
  width: 250px;
  position: fixed;
  background-color: black;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  @include default-transition();
  display: none;
  .side-menu-inner {
    padding: 1rem;
    .side-menu-body {
      a {
        @include default-transition();
        display: block;
        color: white;
        text-decoration: none;
        font-size: 1.5rem;
        font-weight: 400;
        letter-spacing: -0.025rem;
        position: relative;
        &.active {
          color: $text-gray;
        }
      }
    }
  }
  &.visible {
    transform: translateX(0px);
  }
  &.hidden {
    transform: translateX(250px);
  }
  @include mobile {
    display: block;
  }
}

main.menu-active,
.mobile-menu.menu-active {
  opacity: 0.2;
  transform: translateX(-250px);
}

main {
  width: 90%;
  max-width: 1440px;
  margin: 0 auto;
  display: block;
  @include default-transition();
  .content {
    padding: 1.5rem;
    @include mobile {
      padding: $mobile-padding;
    }
  }
  @include mobile {
    width: calc(100% - 2rem);
    padding: 1rem;
  }
}

.loadable-spinner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}
