@import "../styles/load.scss";

footer {
  width: 90%;
  max-width: 1440px;
  margin: 0 auto;
  display: block;
  .footer-inner {
    text-align: center;
    padding: 1rem;
  }
}
