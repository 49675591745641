@import "../../styles/load.scss";

.album-grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 2rem;
  @include mobile {
    margin-top: -1rem;
    grid-template-columns: auto auto auto;
    grid-gap: 1rem;
  }
  .album-grid-photo {
    aspect-ratio: 1/1;
    background-size: cover;
    background-position: center center;
    display: block;
    cursor: pointer;
  }
}

.album-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 250px);
}

.album-password {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 250px);
  form {
    width: 350px;
    display: flex;
    flex-direction: column;
    input,
    button {
      @include remove-webkit();
    }
    input {
      padding: 0.75rem 1rem;
    }
    button {
      background-color: transparent;
      color: white;
      border: 1px solid lighten($base-alternative-black, 20%);
      font-weight: 500;
      margin-top: 0.5rem;
      padding: 0.75rem 1.5rem;
    }
  }
}
