@import "../styles/load.scss";

.cover {
  width: 100%;
  margin: 0 auto;
  display: block;
  aspect-ratio: 16/9;
  background-image: url("../../public/cover.jpg");
  background-size: cover;
  background-position: center center;

  @include mobile {
    width: 100%;
  }
}
