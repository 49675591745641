@import "../load.scss";

.spinner {
  display: inline-block;
  border: 3px solid rgba(white, 0.2);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 0.75s linear infinite;
  -webkit-animation: spin 0.75s linear infinite;
  &.regular {
    width: 50px;
    height: 50px;
  }
  &.small {
    width: 30px;
    height: 30px;
    border-width: 2px;
  }
  &.large {
    width: 70px;
    height: 70px;
    border-width: 4px;
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
