// Base
// *
$base-bg-black: black;
$base-alternative-black: #1e1e1e;
$base-red: #e50102;

// Text
// *
$text-red: #e50102;
$text-gray: #929292;
$text-black: black;

// Border
// *
$border-dark-gray: #3e3e3e;

$mobile-padding: 0.75rem;
