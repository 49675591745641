@import "../../styles/load.scss";

.about {
  max-width: 500px;
  margin: 0 auto;
  padding: 4rem 0;
  display: block;
  @include mobile {
    max-width: 100%;
    padding: 1rem 0;
  }
  &:first-child {
    &::first-letter {
      opacity: 0.5;
      color: white;
      float: left;
      font-family: Georgia;
      font-size: 64px;
      line-height: 50px;
      padding-top: 4px;
      padding-right: 8px;
      padding-left: 3px;
    }
  }
}
