@import "../../styles/load.scss";

.video {
  display: flex;
  flex-direction: column;
  @include mobile {
    margin-top: -1rem;
  }
  .video-player-container {
    position: relative;
    flex: 1;
    aspect-ratio: 16/9;
    background-color: $base-alternative-black;
    .video-player {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
  .video-player-details {
    display: flex;
    justify-content: space-between;
    .video-name-plays {
      h3 {
        margin-bottom: 0;
        @include mobile {
          font-size: 0.9rem;
        }
      }
      .video-plays {
        display: flex;
        align-items: center;
        svg {
          margin-left: -5px;
          margin-right: 5px;
        }
        h5 {
          color: $text-gray;
          margin: 0;
        }
      }
    }
    .video-timestamp {
      display: flex;
      align-items: center;
      gap: 5px;
      h5 {
        color: $text-gray;
      }
    }
  }
}

.video-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 250px);
}
