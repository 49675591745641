@import "../../styles/load.scss";

.recent-albums {
  margin-top: 4rem;
  @include mobile {
    margin-top: 2rem;
  }
  .recent-albums-header {
    max-width: 500px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 3rem;
    display: block;
    @include mobile {
      max-width: 350px;
      margin-bottom: 3rem;
      h2 {
        font-size: 1.15rem;
      }
      p {
        font-size: 0.9rem;
      }
    }
  }
  .recent-albums-grid {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 2rem;
    @include mobile {
      grid-template-columns: auto auto;
      grid-gap: 1rem;
    }
  }
  .recent-albums-links {
    text-align: center;
    padding: 3rem 0;
    a {
      color: white;
      text-decoration: none;
      padding: 0.75rem 1.5rem;
      border: 1px solid lighten($base-alternative-black, 10%);

      display: inline-flex;
      align-items: center;
      align-self: center;
      gap: 0.5rem;
    }
  }
}
