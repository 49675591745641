$desktop-width: 1024px;
$tablet-width: 768px;
$mobile-width: 768px;

// Inline block
// *

@mixin inline-block($position: middle) {
    display: inline-block;
    vertical-align: $position;
}

// Inline block equal
@mixin inline-block-equal($width: 50%, $position: middle) {
    width: $width;
    vertical-align: $position;
    display: inline-block;
}

// Remove webkit
// *
@mixin remove-webkit() {
    -webkit-appearance: none;
    border:0;
    outline: 0;
}

// Position absolute
// And position, default: bottom
// *
@mixin position-absolute($direction: bottom) {
    position: absolute;
    #{$direction}: 0;
}

@mixin position-absolute-all() {
    position: absolute;
    top:0;left:0;right:0;bottom:0;
}

// Position fixed all
// *
@mixin position-fixed-all() {
    position: fixed;
    top:0;left:0;right:0;bottom:0;
}

// Position
@mixin position($position, $top: null, $bottom: null, $left: null, $right: null) {
    position: $position;
    top: $top;
    bottom: $bottom;
    left: $left;
    right: $right;
}

// Square size
// *
@mixin square-size($size) {
    width: $size;
    height: $size;
}

@mixin size($height, $width: 100%) {
    width: $width;
    height: $height;
}

// Set background image settings
// *
@mixin background-image-properties($size, $position) {
    background-size:$size;
    background-position: $position;
    background-repeat: no-repeat;
}

// Background image
// *
@mixin background-image($image) {
    background-image: url($image);
}

// Background image all
// *
@mixin background-image-all($size, $position, $image) {
    background-size:$size;
    background-position: $position;
    background-repeat: no-repeat;
    background-image: url($image);
}

// Font family
// *
@mixin font-type($type) {
    @if $type == light {
        font-family: 'GTWalsheimProLight';
    } @else if $type == regular {
        font-family: 'GTWalsheimProRegular';
    } @else if $type == medium {
        font-family: 'GTWalsheimProMedium';
    } @else if $type == bold {
        font-family: 'GTWalsheimProBold';
    }
}

// Default transition
// *
@mixin default-transition($element: all, $time: 0.2s) {
    -webkit-transition: $element linear $time;
    -moz-transition: $element linear $time;
    -o-transition: $element linear $time;
    transition: $element linear $time;
}

@mixin text-overflow($type: ellipsis) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Desktop
// *
@mixin desktop {
    @media (min-width: #{$desktop-width}) {
      @content;
    }
}

// Tablet
// *
@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
      @content;
    }
}

// Mobile
// *
@mixin mobile {
    @media (max-width: #{$mobile-width}) {
        @content;
    }
}

// Placeholder
// *
@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}  
}