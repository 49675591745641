@import "../load.scss";

.video-card {
  position: relative;
  aspect-ratio: 16/9;
  background-size: cover;
  background-position: center center;
  display: block;
  .video-card-meta {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.75rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: rgb(2, 0, 36);
    background: linear-gradient(
      180deg,
      rgba(2, 0, 36, 1) 0%,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 0.506061799719888) 100%
    );
    h5 {
      color: white;
      font-weight: 500;
      font-size: 1rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0;
    }
  }
}
